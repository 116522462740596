/** @format */

import NavLogo from "../Assets/Logo.svg";
import FooterLogo from "../Assets/Footer Logo.svg";
import Insta from "../Assets/social Instagram.svg";
import Facebook from "../Assets/social Facebook.svg";
import LinkedIn from "../Assets/social Linkedin.svg";
import Twitter from "../Assets/social Twitter.svg";
import Aalim from "../Assets/Aalim.svg";
import Hafeez from "../Assets/Hafeez.svg";
import Student from "../Assets/Student.svg";
import Teacher from "../Assets/Teacher.svg";
import Money from "../Assets/Money.svg";
import calender from "../Assets/Calender.svg";
import campaign from "../Assets/Campaign.svg";
import Donor from "../Assets/Donors.svg";
import Empowering from "../Assets/Empowering.png";
import SlideOne from "../Assets/1.svg";
import SlideOneSmall from "../Assets/1-tab.svg";
import SlideTwo from "../Assets/2.svg";
import SlideTwoSmall from "../Assets/2-tab.svg";
import SlideThree from "../Assets/3.svg";
import SlideThreeSmall from "../Assets/3-tab.svg";
import SlideFour from "../Assets/4.svg";
import SlideFourSmall from "../Assets/4-tab.svg";
import SlideFive from "../Assets/5.svg";
import SlideFiveSmall from "../Assets/5-tab.svg";
import SlideSix from "../Assets/6.svg";
import SlideSixSmall from "../Assets/6-tab.svg";
import statsExpense from "../Assets/statsExpense.svg";
import AboutImgTwo from "../Assets/aboutus2.jpg";
// import AboutImgThree from "../Assets/aboutus3.svg";
import BG2 from "../Assets/BG (2).svg";
import BG3 from "../Assets/BG (3).svg";
import BG4 from "../Assets/BG (4).svg";
import BG5 from "../Assets/BG (5).svg";
import Df from "../Assets/DF(1).jpg";
import Df2 from "../Assets/DF(2).jpg";
import LandingCardImg from "../Assets/LandingCardImg.png";
import LandingCardImg2 from "../Assets/LandingCardImg2.png";
import LandingCardImg3 from "../Assets/LandingCardImg3.png";
import DepartmentImg from "../Assets/Bg.svg";
import NewlyAdded from "../Assets/iconNA.svg";
import CardCalender from "../Assets/CardCalender.svg";
import CardTotalDonation from "../Assets/CardTotalDonation.svg";
import BulletPoint from "../Assets/BulletPoint.svg";
import Location from "../Assets/Location.svg";
import email from "../Assets/email.svg";
import ContactNo from "../Assets/ContactNo.svg";
import DonerCard from "../Assets/Vector.svg";
import date from "../Assets/Vector(2).svg";
import HelpUsImgOne from "../Assets/Mortarboard.svg";
import HelpUsImgTwo from "../Assets/Educational resource.svg";
import HelpUsImgThree from "../Assets/Facility improvement.svg";
import HelpUsImgFour from "../Assets/Teacher training.svg";
import HelpUsImgFive from "../Assets/Needy Student Support.svg";
import BoardOfManagament from "../Assets/BoardOfManagment.svg";
import NoData from "../Assets/NoData.svg";
import Principle from "../Assets/Principal.jpeg";
import President from "../Assets/Prisdent.jpeg";
import Bg404 from "../Assets/Bg404.jpg";
import TotalDonors from "../Assets/totalDonors.svg";
import SponsorsImage from "../Assets/SponsorsImage.jpg";
import employee1 from "../Assets/MuhammadJaved .jpeg";
import employee2 from "../Assets/MuhammadMuhtashim.jpeg";
import employee3 from "../Assets/MuhammadSaqib.jpeg";
import employee4 from "../Assets/MaulanaHabibullah.jpeg";
import employee5 from "../Assets/abQadeer.jpeg";
export default {
  Bg404,
  NavLogo,
  FooterLogo,
  Insta,
  Facebook,
  LinkedIn,
  Twitter,
  Aalim,
  Hafeez,
  Student,
  Teacher,
  Money,
  calender,
  calender,
  campaign,
  Donor,
  Empowering,

  SlideOne,
  SlideOneSmall,
  SlideTwo,
  SlideTwoSmall,
  SlideThree,
  SlideThreeSmall,
  SlideFour,
  SlideFourSmall,
  SlideFive,
  SlideFiveSmall,
  SlideSix,
  SlideSixSmall,
  statsExpense,
  AboutImgTwo,
  SponsorsImage,
  BG2,
  BG3,
  BG4,
  BG5,
  DepartmentImg,
  NewlyAdded,
  CardCalender,
  CardTotalDonation,
  BulletPoint,
  Location,
  email,
  ContactNo,
  DonerCard,
  date,
  LandingCardImg,
  LandingCardImg2,
  LandingCardImg3,
  HelpUsImgOne,
  HelpUsImgTwo,
  HelpUsImgThree,
  HelpUsImgFour,
  HelpUsImgFive,
  BoardOfManagament,
  NoData,
  Df,
  Df2,
  President,
  Principle,
  TotalDonors,
  employee1,
  employee2,
  employee3,
  employee4,
  employee5,
};
