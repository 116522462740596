/** @format */

import React from "react";
import { chipColors, colors, theme } from "../../../Constants/theme";
import { Gallery } from "../Gallery/Index";
import Image from "../../../Constants/Image";
import VideoComponent from "../../Layout/Videos.jsx/Index";

let data = [
  {
    img: Image.President,
    name: "maulana muhammad naushad",
    role: "President",
    date: "2011 - 2024",
  },
  {
    img: Image.Principle,
    name: "Qari muhammad mushahid",
    role: "principal",
    date: "1999 - 2024",
  },
  {
    img: Image.employee1,
    name: "Qari Muhammad Javed Sahab",
    role: "Member",
    date: "1999 - 2024",
  },
  {
    img: Image.employee2,
    name: "Maulana Muhammad Muhtashim",
    role: "Treasure",
    date: "1999 - 2024",
  },
  {
    img: Image.employee3,
    name: "Qari Muhammad Saqib Sahab",
    role: "Ustaz",
    date: "1999 - 2024",
  },
  {
    img: Image.employee4,
    name: "Maulana Habibullah Sahab ",
    role: "Accountent",
    date: "1999 - 2024",
  },
  {
    img: Image.employee5,
    name: "Abdul Qadeer Sahab ",
    role: "Member",
    date: "1999 - 2024",
  },
];

function Index() {
  return (
    <div className='w-[59.17%] max-desktop:w-[650px] max-tablet:w-[90%] max-tablet:max-w-[500px]  m-auto items-center  py-[100px] max-desktop:py-[80px] max-tablet:py-[60px] flex flex-col gap-[110px] max-desktop:gap-[80px] max-tablet:gap-[60px]'>
      <h1
        className='text-[2.25rem] uppercase max-tablet:text-[1.5rem] max-desktop:text-[1.875rem] font-extrabold font-roboto'
        style={{ color: colors.text.light }}
      >
        Board of Management
      </h1>
      {/* <div className='flex flex-col text-center  w-[260px] '>
        <img
          src={data[0].img}
          alt=''
          loading='lazy' // Added lazy loading
          className='h-[299.75px] bg-cover bg-center  w-full rounded-t-[10px]'
        />
        <div className=' w-full rounded-b-[10px] overflow-hidden'>
          <div
            className='w-full py-[10.98px] font-roboto space-y-[5.98px] text-[#D9D9D9]'
            style={{ background: chipColors.color.free }}
          >
            <h1 className='text-[1.275rem] font-extrabold text-wrap'>
              {data[0].name}
            </h1>
            <p className='font-normal text-[0.875rem]'>{data[0].role}</p>
          </div>
        </div>
      </div> */}
      <div className='w-full flex flex-wrap gap-[100px] justify-evenly  gap-y-[100px]'>
        {data.map((item, index) => {
          return (
            <div key={index} className='flex flex-col text-center  w-[250px] '>
              <img
                src={item.img}
                alt=''
                loading='lazy' // Added lazy loading to all images
                className='h-[299.5px] bg-cover bg-center  w-full rounded-t-[10px]'
              />
              <div className=' w-full rounded-b-[10px] overflow-hidden'>
                <div
                  className='w-full py-[6.98px] font-roboto space-y-[2.98px] text-[#D9D9D9]'
                  style={{ background: chipColors.color.free }}
                >
                  <h1 className='text-[1.275rem] px-2 font-extrabold'>
                    {item.name}
                  </h1>
                  <p className='font-normal text-[0.875rem]'>{item.role}</p>
                  {/* <p className='font-normal text-[0.875rem]'>{item.date}</p>   */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <h1
        className='text-[2.25rem] uppercase max-tablet:text-[1.5rem] max-desktop:text-[1.875rem] font-extrabold font-roboto'
        style={{ color: colors.text.light }}
      >
        Our Madrasa
      </h1>
      {/* <Gallery /> */}
      <Gallery />
      <VideoComponent />
    </div>
  );
}
export default Index;
