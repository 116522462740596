/** @format */

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { Navigation, A11y } from "swiper/modules";
import Image from "../../../Constants/Image";
import { colors } from "../../../Constants/theme";
let data = [
  {
    backgroundImage: Image.SlideOne,
    smallBackgroundImage: Image.SlideOneSmall,
    ayat: "مَا نَقَصَتْ صَدَقَةٌ مِنْ مَالٍ، وَمَا زَادَ اللَّهُ عَبْدًا بِعَفْوٍ إِلَّا عِزًّا، وَمَا تَوَاضَعَ أَحَدٌ لِلَّهِ إِلَّا رَفَعَهُ اللَّهُ",
    EnglishTranslationHeading: "Charity does not decrease wealth, ",
    EnglishTranslation:
      "no one forgives another except that Allah increases his honor, and no one humbles himself for the sake of Allah except that Allah raises his status.",
    UrduTranslation:
      "صدقہ مال کو کم نہیں کرتا، اور کوئی معاف نہیں کرتا مگر اللہ اس کی عزت میں اضافہ کرتا ہے، اور جو کوئی اللہ کے لئے عاجزی کرتا ہے اللہ اسے بلند کرتا ہے",
    reference: "Sahih Muslim, Book 32, Hadith 6264",
    reported: "Narrated Abu Huraira: The Prophet (peace be upon him) said",
  },
  {
    backgroundImage: Image.SlideTwo,
    smallBackgroundImage: Image.SlideTwoSmall,
    ayat: "وَجَعَلَنِي مُبَارَكًا أَيۡنَ مَا كُنتُ وَأَوۡصَٰنِي بِٱلصَّلَوٰةِ وَٱلزَّكَوٰةِ مَا دُمۡتُ حَيّٗا",
    EnglishTranslationHeading: "And He has made me blessed,",
    EnglishTranslation:
      "wherever I am and has enjoined upon me prayer and zakah as long as I remain alive.",
    UrduTranslation:
      "اور اس نے مجھے بابرکت کیا ہے جہاں بھی میں ہوں، اور اس نے مجھے نماز اور زکوٰة کا حکم دیا ہے جب تک بھی میں زنده رہوں",
    reference: "Quran (19:31)",
  },
  {
    backgroundImage: Image.SlideThree,
    smallBackgroundImage: Image.SlideThreeSmall,
    ayat: "طَلَبُ الْعِلْمِ فَرِيضَةٌ عَلَى كُلِّ مُسْلِمٍ",
    EnglishTranslationHeading: "Seeking knowledge",
    EnglishTranslation: "is an obligation upon every Muslim.",
    UrduTranslation: "علم حاصل کرنا ہر مسلمان پر فرض ہے۔",
    reference: "Sunan Ibn Mājah 224",
    reported: "Anas ibn Malik reported: The Messenger of Allah , said:",
  },
  {
    backgroundImage: Image.SlideFour,
    smallBackgroundImage: Image.SlideFourSmall,
    ayat: "وَٱلَّذِينَ جَٰهَدُواْ فِينَا لَنَهۡدِيَنَّهُمۡ سُبُلَنَاۚ وَإِنَّ ٱللَّهَ لَمَعَ ٱلۡمُحۡسِنِينَ",
    EnglishTranslationHeading: "And those who strive for Us -",
    EnglishTranslation:
      "We will surely guide them to Our ways. And indeed, Allah is with doers of good.",
    UrduTranslation:
      "اور جو لوگ ہماری راه میں مشقتیں برداشت کرتے ہیں ہم انہیں اپنی راہیں ضرور دکھا دیں گے۔ یقیناً اللہ تعالیٰ نیکو کاروں کا ساتھی ہے",
    reference: "Quran (29:69)",
  },
  {
    backgroundImage: Image.SlideFive,
    smallBackgroundImage: Image.SlideFiveSmall,
    ayat: "وَيُطْعِمُونَ الطَّعَامَ عَلَىٰ حُبِّهِ مِسْكِينًا وَيَتِيمًا وَأَسِيرًا إِنَّمَا نُطْعِمُكُمْ لِوَجْهِ اللَّهِ لَا نُرِيدُ مِنكُمْ جَزَاءً وَلَا شُكُورًا",
    EnglishTranslationHeading: "And they give food, ",
    EnglishTranslation:
      "in spite of love for it, to the needy, the orphan, and the captive, [saying], 'We feed you only for the countenance of Allah. We wish not from you reward or gratitude.",
    UrduTranslation:
      "اور وہ کھانا کھلاتے ہیں، اس کی محبت کے باوجود، مسکین، یتیم اور قیدی کو، (کہتے ہیں) 'ہم تمہیں صرف اللہ کی رضا کے لئے کھلاتے ہیں۔ ہم تم سے نہ بدلہ چاہتے ہیں اور نہ شکرگزاری۔",
    reference: "Quran (9:34)",
  },
  {
    backgroundImage: Image.SlideSix,
    smallBackgroundImage: Image.SlideSixSmall,
    ayat: "خَيْرُكُمْ مَنْ تَعَلَّمَ الْقُرْآنَ وَعَلَّمَهُ",
    EnglishTranslationHeading: "The best among you",
    EnglishTranslation:
      "(Muslims) are those who learn the Qur'an and teach it.",
    UrduTranslation:
      "رسول اللہ صلی اللہ علیہ وسلم نے فرمایا تم میں سب سے بہتر وہ ہے جو قرآن مجید پڑھے اور پڑھائے ۔",
    reference: "Sahih al-Bukhari 5027",
    reported: "Narrated `Uthman: The Prophet (ﷺ) said,",
  },
];
export default function App() {
  return (
    <Swiper
      modules={[Navigation, Pagination, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      pagination={{
        el: ".swiper-pagination",
        type: "custom",
        clickable: true,
        renderCustom: function (swiper, current, total) {
          const bullets = [];
          for (let i = 1; i <= total; i++) {
            const isActive = i === current ? "active" : "";
            bullets.push(
              `<li class="bullet ${isActive}" data-index="${i}"></li>`
            );
          }

          return `<ul class="custom-pagination">${bullets.join("")}</ul>`;
        },
      }}
      navigation
      scrollbar={{ draggable: true }}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <div
            className='w-full max-desktop:hidden max-tablet:hidden h-[80vh] flex bg-cover overflow-hidden bg-center bg-no-repeat'
            style={{ backgroundImage: `url("${item.backgroundImage}")` }}
          >
            <div className='w flex max-w-[800px] flex-col items-center gap-[52px] py-[155px] pl-8 '>
              <p
                className={`text-[1.5rem] font-medium text-center font-amiri text-[${colors.tertiary.dark}]`}
              >
                {item.ayat}
              </p>
              <div
                className={`max-w-[700px] text-center leading-[50px] text-[${colors.tertiary.dark}]`}
              >
                <p className='max-desktop:text-[0.9rem] max-tablet:text-[0.9rem] leading-[14px] font-playfair font-normal'>
                  {item.reported}
                </p>
                <h1
                  style={{ fontFamily: "playfair" }}
                  className={`text-[3rem] font-black text-[${colors.tertiary.dark}]`}
                >
                  {item.EnglishTranslationHeading}
                </h1>
                <p
                  style={{ fontFamily: "playfair" }}
                  className='text-[1.5rem] leading-[24px] font-normal'
                >
                  {item.EnglishTranslation}
                </p>
                <p className='text-[0.975rem] italic'>{item.reference}</p>
              </div>
              <div className='text-center'>
                <p className='text-[2rem] font-medium font-lateef'>
                  {item.UrduTranslation}
                </p>
              </div>
            </div>
          </div>
          <div
            className='w-full desktop:hidden flex justify-center h-[80vh] items-end bg-cover bg-center bg-no-repeat'
            style={{ backgroundImage: `url("${item.smallBackgroundImage}")` }}
          >
            <div className='flex max-desktop:max-w-[700px] max-tablet:max-w-[320px] text-center flex-col items-center gap-[35px] max-tablet:gap-[12px] max-desktop:my-16 max-tablet:my-12'>
              <p
                className={`text-wrap break-words text-center whitespace-nowrap max-desktop:text-[1.5rem] max-tablet:text-[1rem] font-medium font-amiri text-[${colors.tertiary.dark}]`}
              >
                {item.ayat}
              </p>
              <div className={`text-center text-[${colors.tertiary.dark}]`}>
                <p className='max-desktop:text-[0.9rem] max-tablet:text-[0.8rem] leading-[14px] font-playfair font-normal'>
                  {item.reported}
                </p>
                <h1
                  style={{ fontFamily: "playfair" }}
                  className={`max-desktop:text-[2.2rem] max-tablet:text-[1.65rem] leading-[30px] font-black text-[${colors.tertiary.dark}]`}
                >
                  {item.EnglishTranslationHeading}
                </h1>
                <p
                  style={{ fontFamily: "playfair" }}
                  className='max-desktop:text-[1.1rem] max-tablet:text-[0.9rem] leading-[20px] font-normal'
                >
                  {item.EnglishTranslation}
                </p>
                <p className='max-desktop:text-[0.775rem] max-tablet:text-[0.7rem] italic'>
                  {item.reference}
                </p>
              </div>
              <div className='text-center'>
                <p className='max-desktop:text-[1.125rem] max-tablet:text-[1rem] font-medium font-lateef'>
                  {item.UrduTranslation}
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className='swiper-pagination'></div>
    </Swiper>
  );
}
