/** @format */

import React from "react";
import NavbarCustom from "../../Components/Layout/Navbar/NavbarCustom";
import Swiper from "../../Components/Layout/Swiper/Swiper";
import Dashboard from "../../Components/Layout/Dashboard/Index";
import Footer from "../../Components/Layout/Footer/Index";
import Image from "../../Constants/Image";
import Card1 from "../../Components/Layout/landingCard/LandingCard";
import Timeline from "../../Components/Layout/Timeline/Index";
import { Helmet } from "react-helmet";

const cardData = [
  {
    title: "EMPOWERING MINDS ENRICHING SOULS",
    text1:
      "At our Madarasa, we blend traditional Islamic teachings with modern education to cultivate well-rounded individuals.",
    text2:
      "Our experienced educators, supportive community, and holistic approach ensure that students not only excel academically but also grow spiritually and morally.",
    text3:
      "Choose us for a nurturing environment where every child is valued and guided to achieve their full potential.",
    img: Image.LandingCardImg3,
  },
  {
    title: "Inspiring Future Generations",
    text1:
      "Our vision is to create a vibrant learning community where Islamic values and contemporary knowledge coexist harmoniously.",
    text2:
      "We aim to produce individuals who are not only academically proficient but also ethically sound, contributing positively to society.",
    text3:
      "By fostering an environment of continuous learning and personal growth, we aspire to be a beacon of excellence in Islamic and secular education.",
    img: Image.LandingCardImg,
  },
  {
    title: "Committed to Excellence",
    text1:
      "At our Madarasa, we blend traditional Islamic teachings with modern education to cultivate well-rounded individuals.",
    text2:
      "Our experienced educators, supportive community, and holistic approach ensure that students not only excel academically but also grow spiritually and morally.",
    text3:
      "Choose us for a nurturing environment where every child is valued and guided to achieve their full potential.",
    img: Image.Empowering,
  },
  {
    title: "Guidance Towards Success",
    text1:
      "Our primary aim is to offer a balanced education that caters to the intellectual, spiritual & emotional needs of our students.",
    text2: "Our objectives include:",
    text3: [
      "Delivering high-quality Islamic and secular education.",
      "Encouraging a deep understanding and practice of Islamic values.",
      "Promoting academic excellence and lifelong learning.",
      "Fostering a sense of community and social responsibility.",
      "Preparing students to face future challenges with confidence.",
    ],
    img: Image.LandingCardImg2,
  },
];

function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Home - Jamiatul Irshad</title>
        {/* <link rel='canonical' href='http://mysite.com/example' /> */}
      </Helmet>
      <NavbarCustom />

      <Swiper />
      <Dashboard />
      {/* <div className='w-full flex flex-col max-desktop:gap-y-32 max-tablet:gap-y-20'> */}
      {cardData.map((item, index) => (
        <Card1
          key={index}
          title={item.title}
          text1={item.text1}
          text2={item.text2}
          text3={item.text3}
          img={item.img}
          imageOnLeft={index % 2 === 1}
        />
      ))}
      {/* </div> */}
      <Timeline />
      <Footer />
    </div>
  );
}

export default Home;
